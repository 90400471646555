export const environment = {
  production: true,

   FRONT_API_URL: 'https://c4w9y8ef78.execute-api.ap-south-1.amazonaws.com/Prod/', //test front-end preview

   API_URL: 'https://st-admin-backend.sportstiger.co/v2/', //test server

   WEBSITE_URL:"https://sportstiger.co",
   
  ImageBaseUrl:'https://sports-365.s3.amazonaws.com/'
};
