import {Injectable} from "@angular/core";

class RouteData {
  data: any;
  url: string;
}

@Injectable()
export class RouteDataHelper {
  public routeDataList: Array<RouteData> = [];
  public currentRouteData;

  constructor() {
  }

  public setData(data) {
    this.routeDataList.push(data);
  }

  public getData(url) {
    let routeData;
    if (this.routeDataList && this.routeDataList.length > 0) {
      routeData = this.routeDataList.find(r => r.url == url);
      if (routeData) {
        this.routeDataList.splice(this.routeDataList.indexOf(routeData), 1);
        return routeData;
      } else {
        return null;
      }
    }
  }

  public clearData() {
    this.routeDataList = [];
  }
}
