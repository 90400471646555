
export class UrlConstant {

    public static get LOGIN_PATH(): string { return 'login' }

    public static get DASH_BORAD_PATH(): string { return 'dashboard' }

    public static get TOPIC_PATH(): string { return 'topic' }

    public static get EMPLOYEE_MANAGER_PATH(): string { return 'employee-manager' }

    public static get TASK_MANAGER_PATH(): string { return 'task-manager' }

    public static get CREATE_EMPLOYEE_PATH(): string { return 'create-employee' }

    public static get ALL_EMPLOYEE_PATH(): string { return 'all-employee' }

    public static get ALL_TOPIC_PATH(): string { return 'all-topics' }

    public static get FRONTEND_PATH(): string { return 'http://node.sportstiger.co' }

    

}

