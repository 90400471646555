import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppConstant } from '../constant/app.constant';
import { UrlConstant } from '../constant/url.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  role_type: any;

  constructor(public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    let userRole = JSON.parse(localStorage.getItem(AppConstant.USER_TYPE_KEY));
    if (!this.getCurrentUser()) {
      this.router.navigate(['/' + UrlConstant.LOGIN_PATH]);
      return false;
    }
    const excludedUrls = ['/dashboard', '/posts/create', '/posts/category', '/posts/list', '/posts/update', '/news-detail', '/coupons', '/betting-page-manager', '/website-manager/betting-sub-header-menu-manager', '/table-manager/coupon-table', '/table-manager/coupon-list','/website-manager/menu-manager'];

    let currentUrl = state.url.split('?')[0];
    
    const shouldExclude = excludedUrls.every(url => currentUrl !== url);
    if (userRole == 10 && shouldExclude) {
      this.router.navigate(['/dashboard']);
      return false;
    }
    return true;

  }

  getCurrentUser() {
    const token = localStorage.getItem(AppConstant.USER_TOKEN_KEY);
    if (token) {
      return true;
    } else {
      return false;
    }

  }
}
