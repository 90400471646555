import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IpServiceService } from './services/ip-service.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private _route: Router,
    private _location: Location, private ip: IpServiceService, private _tosterServices: ToastrService) {
  }

  ngOnInit() {
    this.getIP();
    /* if the User is alrady login
     and he refresh the Page and he Do
      not Logout so directaly it will take to the DashBorad Page
      (if the Token is define it wil take to the DashBorad Page)  */

    // if (localStorage.getItem(AppConstant.USER_TOKEN_KEY) != undefined && (this._location.path() =='/login' || this._location.path() =='')) {
    //   console.log(this._location.path());
    //   this._route.navigate([UrlConstant.DASH_BORAD_PATH])
    // }

  }
  getIP() {
    this.ip.getIPAddress().subscribe((res: any) => {
      //localStorage.setItem('ipAddress', res.ip);
      try {
        localStorage.setItem('ipAddress', res.ip);
      } catch (error) {
        this._tosterServices.error(error);
      }
    });
  }
}
