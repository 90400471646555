import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JasperoConfirmationsModule } from '@jaspero/ng2-confirmations';
import { RouteDataHelper } from './constant/routeData.constant';
import { CKEditorModule } from 'ng2-ckeditor';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { Select2Module } from 'ng2-select2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    NgbModule,
    AngularEditorModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    JasperoConfirmationsModule,
    Select2Module,

    ToastrModule.forRoot({
      // timeOut: 10000,
      preventDuplicates: true
    }),

  ],
  providers: [RouteDataHelper],
  bootstrap: [AppComponent]
})
export class AppModule {
}
